import { type LocalizableString, Lang } from '@/types/form-fields-v2';
import type {
  ClientInvoiceItem,
  ClientInvoiceItemMapped
} from '@/types/provider-clients';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

/** Formats a number to look like US Currency
 * @example $12,345.67
 * @param a number (Not a string)
 * @link see MDN documentation for details: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 */
export function formatMoney(input: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(input);
}

/**
 * Converts a string representing a dollar amount into a float.
 * @example input: "$12.56" > 12.56
 * @param input
 * @returns
 */
export function unformatMoney(input: string): number {
  // Remove any non-digit characters
  let formattedValue = formatStringNumbersOnly(input);

  // Remove any leading zeros
  formattedValue = formattedValue.replace(/^0+/, '');

  // Make sure the it's at least 3 digits in length (add back leading zeros if needed)
  if (formattedValue.length < 3) {
    formattedValue = '0'.repeat(3 - formattedValue.length) + formattedValue;
  }

  // Add the decimal
  const dollars = formattedValue.substring(0, formattedValue.length - 2);
  const cents = formattedValue.substring(formattedValue.length - 2);
  const currencyString = `${dollars}.${cents}`;

  return parseFloat(currencyString);
}

/**
 * Formats a string to look like a phone number
 * @example (123) 456-7890
 * @param phone
 * @returns a formatted string
 */
export function formatPhone(phone: string) {
  return phone
    .replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

/**
 * Cleans a string of everything except numeric characters
 * @param string
 * @returns a string made up of only numeric characters
 */
export function formatStringNumbersOnly(input: unknown): string {
  const inputString = typeof input === 'string' ? input : String(input);
  return inputString.replace(/\D/g, '');
}

/**
 * Translates a JSON object representing a form based on an optional "lang" property.
 * If a string is passed, it simply returns the string.
 * @param obj - The object or string to translate.
 * @param lang - The language to translate to.
 * @returns The translated object or the original string.
 */
// import { isString } from 'lodash-es';
export const translate = (
  item: LocalizableString,
  lang: Lang | undefined
): string => {
  return window.translate(item, lang);
};

/**
 * Formats a string to look like a Date with a Time.
 * Will display in local time of the user's browser.
 * @example Wednesday, 10/25/2023 at 11:00 AM
 * Uses date-fns library
 * @param a datetime string
 * @link see JavaScript Date() constructor for acceptable types of string https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
 */
export function fancyDateTime(dateTime: string) {
  return `${format(new Date(dateTime), 'EEEE, M/d/yyyy')} at ${format(
    new Date(dateTime),
    'h:mm a'
  )}`;
}

/**
 * Formats a string to look like a Date with a Time.
 * Will display in local time of the user's browser.
 * @example Wednesday, 10/25/2023 at 11:00 AM
 * Uses date-fns library
 * @param a datetime string
 * @link see JavaScript Date() constructor for acceptable types of string https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
 */
export function fancyAzDateTime(dateTime: string) {
  const date = new Date(dateTime);
  const tz = 'America/Phoenix';
  const azDateTime = toZonedTime(date, tz);

  return `${format(azDateTime, 'EEEE, M/d/yyyy')} at ${format(
    new Date(azDateTime),
    'h:mm a'
  )}`;
}

/**
 * Formats a string to look like a Date:
 * @example Wednesday 10/25/2023
 * Uses date-fns library
 * @param a datetime string
 * @link see JavaScript Date() constructor for acceptable types of string https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
 */
export function fancyDate(date: string) {
  return `${format(new Date(date), 'EEEE P')}`;
}

/**
 * Formats a string to look like a Date:
 * @example 10/25/2023
 * Uses date-fns library
 * @param a datetime string
 * @link see JavaScript Date() constructor for acceptable types of string https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
 */
export function simpleDate(date: string) {
  return `${format(new Date(date), 'P')}`;
}

/**
 * Formats a string to look like a Time:
 * @example 11:00 AM
 * Uses date-fns library
 * @param a datetime string
 * @link see JavaScript Date() constructor for acceptable types of string https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
 */
export function fancyTime(dateTime: string) {
  return `${format(new Date(dateTime), 'p')}`;
}

/**
 * Format utility specific to client invoice item dates as seen in the previous balance and payments made section of the invoice summary.
 * @example March 14th, 2023 would be displayed as 031423
 * Uses date-fns library
 * @param Date
 * @returns formatted string
 */
export function clientInvoiceDateFormat(input: Date) {
  return format(new Date(input), 'MMddyyyy');
}

/**
 * Formats a string time to Arizona time
 * @param dateTime: string
 * @returns string
 */
export function ensureArizonaTimeString(dateTime: string): string {
  const azDateTime = new Date(dateTime);
  const tz = 'America/Phoenix';
  return toZonedTime(azDateTime, tz).toString();
}

/**
 * Formats a date time with a UTC offset to Arizona time
 * @param dateTime: Date
 * @returns Date
 */
export function ensureArizonaTimeDate(dateTime: Date): Date {
  const tz = 'America/Phoenix';
  return toZonedTime(dateTime, tz);
}

export function formattedCCExpirationDate(date: string): string {
  const justNumbers = formatStringNumbersOnly(date);
  const YYYYMM = `${justNumbers.slice(2)}${justNumbers.slice(0, 2)}`;
  return YYYYMM;
}

export function unformatCCExpDate(date: string): string {
  const pieces = date.split('');
  return `${pieces[4]}${pieces[5]}/${pieces[0]}${pieces[1]}${pieces[2]}${pieces[3]}`;
}

export function getCardBrand(ccNum: string): string {
  const cleaned = formatStringNumbersOnly(ccNum);
  if (cleaned.at(0) == '3') return 'AX';
  else if (cleaned.at(0) == '4') return 'VI';
  else if (cleaned.at(0) == '5') return 'MC';
  else if (cleaned.at(0) == '6') return 'DI';
  else return 'invalid';
}

/**
 * * Maps a set of client invoice items into an unflattened object that is more UI-compatible.
 * @param items
 * @returns an array of mapped items with header data.
 */
export function mapClientInvoiceLineItems(
  items: ClientInvoiceItem[]
): ClientInvoiceItemMapped[] {
  const accessions = [...new Set(items.map(x => x.accnId))];

  const output = [] as ClientInvoiceItemMapped[];

  accessions.forEach((accn: string) => {
    const mappedItems = items.filter(
      i => i.accnId == accn
    ) as ClientInvoiceItem[];

    const mappedItem: ClientInvoiceItemMapped = {
      patientName: mappedItems![0].patientName,
      dateOfService: mappedItems![0].dateOfService,
      accession: mappedItems![0].accnId,
      chartId: mappedItems![0].chartId,
      items: mappedItems
    };

    output.push(mappedItem);
  });

  return output;
}

/**
 * Converts an object into a base64-encoded string.
 * Used to cuircumvent Azure firewall issues.
 * @param object - a generic object that will be stringified and base64 encoded.
 * @returns an encoded string representing an object/ payload.
 */
export function stringifyAndEncodeObject(object: object): string {
  const stringified = JSON.stringify(object);
  const encoded = btoa(stringified);
  return encoded;
}

