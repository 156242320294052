// #region GENERAL IMPORTS
import './polyfills';
import './globals';
import { createApp } from 'vue';
import App from './App.vue';
import errorHandler from './plugins/error-handler';
// #endregion GENERAL IMPORTS

// #region SETUP VUE APPLICATION
const app = createApp(App);
app.use(errorHandler);
app.config.warnHandler = () => {};
// #endregion SETUP VUE APPLICATION

// #region CONFIGURE PINIA
import pinia from './stores';
app.use(pinia);
// #endregion CONFIGURE PINIA

// #region CONFIGURE VUE-ROUTER
import router from './router';
const appRouter = router();
app.use(appRouter);
// #endregion CONFIGURE VUE-ROUTER

// #region CUSTOM DIRECTIVES
import { createRouterifyAnchors } from '@/directives/RouterifyAnchors';
app.directive('routerify-anchors', createRouterifyAnchors(appRouter));

import ClickOutsideDirective from './directives/ClickOutsideDirective';
app.directive('click-outside', ClickOutsideDirective);

import ScrollyDirective from './directives/ScrollyDirective';
app.directive('scrolly', ScrollyDirective);

import ScrollyWatchDirective from './directives/ScrollyWatchDirective';
app.directive('scrolly-watch', ScrollyWatchDirective);

import { vMaska } from 'maska/vue';
app.directive('maska', vMaska);
// #endregion CUSTOM DIRECTIVES

// # Finally, mount the application
appRouter.isReady().then(() => app.mount('#app'));
